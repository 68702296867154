import { createSlice } from '@reduxjs/toolkit'
import api, { API_FETCH_COUNTRIES } from '../utils/axios'

const initialState = {
  loading: false,
  availableCountries: [],
  langChange: null
}

const dashboard = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload.loading
    },
    setCountriesList: (state, action) => {
      state.availableCountries = action.payload
    },
    setlangChangeStore: (state, action) => {
      state.langChange = action.payload
    }
  }
})

export const { setLoading, setCountriesList, setlangChangeStore } = dashboard.actions
export default dashboard.reducer

export const getData = ({ data, callback }) => async (dispatch) => {
  try {
    dispatch(setLoading({ loading: true }))
    const apiResponse = await api.get(API_FETCH_COUNTRIES)
    if (apiResponse) {
      dispatch(setCountriesList(apiResponse.data.countries))
      dispatch(setLoading({ loading: false }))
      callback && callback(apiResponse.data.countries)
    }
  } catch (error) {
    dispatch(setLoading({ loading: false }))
  }
}

export const clearStore = () => async (dispatch) => {
  dispatch(setCountriesList([]))
}

export const langChangeStore = ({ data }) => async (dispatch) => {
  dispatch(setlangChangeStore(data))
}
