export default {
  Edit: 'تعديل',
  SavetoReload: 'حفظ لإعادة التحميل',
  Name: 'الاسم',
  common: {
    save: 'حفظ',
    fieldRequiredMessage: 'هذا الحقل مطلوب',
    invalidEmail: 'العنوان خطأ',
    loading: 'جاري التحميل',
    emailValidationMessage: 'الرجاء إدخال عنوان بريد إلكتروني صالح',
    passwordMatch: 'يجب أن تكون كلمتا المرور متطابقتين',
    submit: 'إرسال',
    passwordValidation: 'يجب أن تتكون كلمة المرور من 8 أحرف على الأقل، ويجب أن تحتوي على أحرف كبيرة وصغيرة مثال (aA)، على رقم على الأقل مثال (7) ، وعلى أي من الرموز الخاصة الموجودة في لوحة المفاتيح مثال ($#&)',
    subscribe: 'اشترك في النشرة الإخبارية العامة للاتحاد البرلماني الدولي',
    cancel: 'إلغاء',
    update: 'تحديث',
    emailError: 'يجب أن يكون البريد الإلكتروني الأساسي والبريد الإلكتروني الثانوي مختلفين',
    nameValidation: 'الرجاء إدخال اسم صالح',
    passwordLength: 'يجب أن تتكون كلمة المرور من 8 أحرف على الأقل'
  },
  login: {
    heading: 'تسجيل الدخول',
    email: 'عنوان البريد الإلكتروني',
    password: 'كلمة المرور',
    forgot: 'نسيت كلمة المرور؟',
    new: 'مستخدم جديد؟'
  },
  register: {
    heading: 'إنشاء حساب',
    haveAccount: 'هل لديك حساب بالفعل؟',
    login: 'تسجيل الدخول',
    upload: 'صورة الصفحة الشخصية'
  },
  forgot: {
    heading: 'نسيت كلمة المرور',
    content: 'أدخل بريدك الإلكتروني فيما يلي، وسنرسل لك إرشادات حول كيفية إعادة تعيين كلمة المرور الخاصة بك.',
    link: 'انتقل إلى تسجيل الدخول'
  },
  reset: {
    heading: 'إعادة تعيين كلمة المرور'
  },
  upload: {
    upload: 'تحميل صورة',
    change: 'تغيير صورة الصفحة الشخصية',
    remove: 'حذف',
    sizeError: 'يجب أن يكون حجم الصور التي تم تحميلها أقل من 2 ميجابايت',
    maxSize: 'الحجم الأقصى المسموح به هو'
  },
  profile: {
    heading: 'الصفحة الشخصية',
    fullName: 'الاسم الكامل',
    edit: 'تعديل الصفحة الشخصية',
    delete: 'حذف الحساب',
    currentPass: 'كلمة المرور الحالية',
    newPass: 'كلمة المرور الجديدة',
    confPass: 'تأكيد كلمة المرور الجديدة'
  },
  mapDisclaimer: {
    open: 'تنويه: التسميات المستخدمة وعرض المواد على هذه الخريطة لا تعبر عن أي رأي كان من جانب الاتحاد البرلماني الدولي بشأن الوضع القانوني لأي دولة أو إقليم أو مدينة أو منطقة أو بشأن سلطاتها، أو بخصوص تحديد الحدود أو الفواصل الحدودية.',
    close: 'تنويه'
  },
  Upload: 'تحميل صورة الصفحة الشخصية',
  Select: 'اختيار',
  Email: 'عنوان البريد الإلكتروني',
  SecondaryEmail: 'عنوان البريد الإلكتروني الثانوي (اختياري)',
  Copyright: 'حقوق الملكية © الاتحاد البرلماني الدولي',
  IPUMembershipCategory: 'فئة العضوية بالاتحاد البرلماني الدولي',
  ConfirmPassword: 'تأكيد كلمة المرور',
  Login: 'تسجيل الدخول',
  Register: 'إنشاء حساب',
  Profile: 'الصفحة الشخصية',
  Logout: 'تسجيل الخروج',
  mapInfo: 'للاطلاع على آخر المعلومات المتعلقة بالاتحاد البرلماني الدولي، اشترك في رسالتنا الإخبارية',
  Map: 'خريطة',
  Faq: 'أسئلة شائعة',
  Secondarymail: 'عنوان البريد الإلكتروني الثانوي',
  Password: 'كلمة المرور',
  ChangePassword: 'تغيير كلمة المرور',
  1000: 'انتهت جلستك. الرجاء تسجيل الدخول للمتابعة.',
  1001: 'البريد الإلكتروني الأساسي غير صالح.',
  1002: 'البريد الإلكتروني الثانوي غير صالح.',
  1003: 'كلمتا المرور غير متطابقتين.',
  1004: 'يجب أن تتكون كلمة المرور من 8 أحرف على الأقل.',
  1005: 'يجب أن تحتوي كلمة المرور على رمز خاص على الأقل.',
  1006: 'يجب أن تحتوي كلمة المرور على حرف كبير على الأقل.',
  1007: 'يجب أن تحتوي كلمة المرور على حرف صغير على الأقل.',
  1008: 'يجب أن تحتوي كلمة المرور على رقم على الأقل.',
  1009: 'فئة العضوية بالاتحاد البرلماني الدولي غير صالحة.',
  1010: 'لا يمكن معالجة الصورة الرمزية.',
  1011: 'لا يمكن قراءة ملف الصورة.',
  1012: 'البريد الإلكتروني مسجل بالفعل.',
  1013: 'لا يمكن أن يكون البريد الإلكتروني الأساسي هو البريد الإلكتروني الثانوي للمستخدم الحالي.',
  1014: 'البريد الإلكتروني الثانوي مستخدم بالفعل.',
  1015: 'تم تحديث بيانات المستخدم بنجاح.',
  1016: 'المستخدم غير موجود.',
  1017: 'لقد أرسلنا لك عبر البريد الإلكتروني رابط إعادة تعيين كلمة المرور!.',
  1018: 'تعذر إرسال البريد الإلكتروني الخاص بإعادة تعيين كلمة المرور.',
  1019: 'البريد الإلكتروني الشخصي مسجل بالفعل.',
  1020: 'تم تحديث كلمة المرور بنجاح. سيتم تسجيل خروجك بعد قليل.',
  1021: 'كلمة المرور القديمة غير صحيحة.',
  1022: 'مستخدم غير صالح.',
  1023: 'انتهت صلاحية رابط إعادة تعيين كلمة المرور!',
  1024: 'تم تحديث كلمة المرور بنجاح.',
  1025: 'Iرابط إعادة تعيين كلمة المرور غير صالح.',
  1026: 'تمت العملية بنجاح',
  1027: 'تم حذف حساب المستخدم بنجاح.',
  1028: 'حسابك غير نشط.',
  1033: 'لم يتم تنشيط حساب المستخدم أو قد تم حظره.',
  1034: 'البريد الإلكتروني أو كلمة المرور غير صحيحة.',
  1035: 'تم تسجيل الكثير من محاولات الدخول الفاشلة من عنوان بروتوكول الإنترنت الخاص بك. تم حظر عنوان بروتوكول الإنترنت هذا مؤقتا.',
  1036: 'لا يمكن الوصول إلى هذا المسار إلا من قبل مستخدمين مجهولين.',
  1037: 'تم التسجيل بنجاح. الرجاء انتظار موافقة المسؤول.',
  1039: 'موقع الاتحاد البرلماني الدولي قيد الصيانة حاليا. سوف نعود قريبا. شكرا لك على صبرك.',
  1038: 'تم تحديث كلمة المرور. سيتم تسجيل خروجك بعد قليل.',
  delete: {
    title: 'حذف الحساب؟',
    content: 'هل أنت متأكد من رغبتك في حذف هذا الحساب؟',
    delete: 'حذف',
    cancel: 'إلغاء',
    picContent: 'هل أنت متأكد من رغبتك في حذف هذه الصورة؟',
    picTitle: 'حذف الصورة؟',
    remove: 'حذف'
  },
  apologies: 'عفوا. قد تعذر علينا العثور على الصفحة التي تبحث عنها.',
  goHome: 'توجه إلى الصفحة الرئيسية',
  goLogin: 'توجه إلى صفحة تسجيل الدخول',
  faq: {
    header: 'أسئلة شائعة',
    noresult: 'لم يتم العثور على نتائج!',
    nodata: 'لم يتم العثور على بيانات!'
  },
  countryDetails: {
    countryCode: 'رمز البلد',
    assembly: 'الجمعية',
    parliament: 'البرلمان',
    senate: 'مجلس الشيوخ',
    nationalLegislation: 'التشريعات الوطنية الخاصة بمكافحة الإرهاب والتطرف العنيف',
    agreements: 'الاتفاقات الموقعة مع الأمم المتحدة',
    workWithNGOs: 'العمل مع المنظمات غير الحكومية و/أو منظمات المجتمع المدني',
    conferences: 'المؤتمرات المستضافة',
    otherInformation: 'معلومات أخرى',
    committee_in_charge: 'اللجنة المكلفة',
    president_of_committee: 'رئيس اللجنة',
    vice_president_of_committee: 'نائب رئيس اللجنة',
    secretary_of_committee: 'أمين اللجنة',
    otherLegislation: 'تشريعات أخرى ذات صلة',
    linkWithLeg: 'رابط للتشريع الخاص بمكافحة الإرهاب والتطرف العنيف'
  },
  map: {
    linkToDetails: 'لجنة مكافحة الإرهاب والتطرف العنيف',
    eh: 'إقليم غير متمتع بالحكم الذاتي',
    fk: 'ثمة نزاع قائم بين حكومتي الأرجنتين والمملكة المتحدة لبريطانيا العظمى بشأن السيادة على جزر فوكلاند (مالفيناس)',
    'dt-ac': 'إقليم محل نزاع بين الهند والصين',
    'dt-jk': 'ملاحظة: تمثل هذه المنطقة تقريبا خط المراقبة في جامو وكشمير الذي اتفق عليه الهند وباكستان. لم يتفق الطرفان بعد على الوضع النهائي لجامو وكشمير.',
    'dt-it': 'إقليم محل نزاع بين كينيا وجنوب السودان',
    sd: 'لم يتم بعد تحديد الحدود النهائية بين جمهورية السودان وجمهورية جنوب السودان',
    ss: 'لم يتم بعد تحديد الحدود النهائية بين جمهورية السودان وجمهورية جنوب السودان',
    pr: 'الولايات المتحدة الأمريكية',
    tw: '', // Don't show any notes for taiwan
    ipuMember: 'عضو في الاتحاد البرلماني الدولي',
    nonIpu: 'ليس عضوا في الاتحاد البرلماني الدولي',
    provinceOfChina: ' (المقاطعة الصينية)'
  },
  errorBoundary: {
    heading: 'حدث خطأ ما!',
    message: 'يمكنك إما إعادة المحاولة الآن أو المحاولة مرة أخرى لاحقا.',
    retry: 'المحاولة مرة أخرى'
  },
  countries: {
    af: 'أفغانستان',
    al: 'ألبانيا',
    dz: 'الجزائر',
    ad: 'أندورا',
    ao: 'أنغولا',
    ag: 'أنتيغوا وبربودا',
    ar: 'الأرجنتين',
    am: 'أرمينيا',
    au: 'أستراليا',
    at: 'النمسا',
    az: 'أذربيجان',
    bs: 'جزر البهاما',
    bh: 'البحرين',
    bd: 'بنغلاديش',
    bb: 'بربادوس',
    by: 'بيلاروس',
    be: 'بلجيكا',
    bz: 'بليز',
    bj: 'بنين',
    bt: 'بوتان',
    bo: 'بوليفيا (دولة-المتعددة القوميات)',
    ba: 'البوسنة والهرسك',
    bw: 'بوتسوانا',
    br: 'البرازيل',
    bn: 'بروني دار السلام',
    bg: 'بلغاريا',
    bf: 'بوركينا فاسو',
    bi: 'بوروندي',
    cv: 'الرأس الأخضر',
    kh: 'كمبوديا',
    cm: 'الكاميرون',
    ca: 'كندا',
    cf: 'جمهورية أفريقيا الوسطى',
    td: 'تشاد',
    cl: 'شيلي',
    cn: 'الصين',
    co: 'كولومبيا',
    km: 'جزر القمر',
    cg: 'الكونغو',
    cr: 'كوستاريكا',
    ci: 'كوت ديفوار',
    hr: 'كرواتيا',
    cu: 'كوبا',
    cy: 'قبرص',
    cz: 'جمهورية التشيك',
    kp: 'جمهورية كوريا الشعبية الديمقراطية',
    cd: 'جمهورية الكونغو الديمقراطية',
    dk: 'الدانمرك',
    dj: 'جيبوتي',
    dm: 'دومينيكا',
    do: 'الجمهورية الدومينيكية',
    ec: 'الإكوادور',
    eg: 'مصر',
    sv: 'السلفادور',
    gq: 'غينيا الاستوائية',
    er: 'إريتريا',
    ee: 'إستونيا',
    sz: 'إسواتيني',
    et: 'إثيوبيا',
    fj: 'فيجي',
    fi: 'فنلندا',
    fr: 'فرنسا',
    ga: 'الغابون',
    gm: 'غامبيا',
    ge: 'جورجيا',
    de: 'ألمانيا',
    gh: 'غانا',
    gr: 'اليونان',
    gd: 'غرينادا',
    gt: 'غواتيمالا',
    gn: 'غينيا',
    gw: 'غينيا - بيساو',
    gy: 'غيانا',
    ht: 'هايتي',
    hn: 'هندوراس',
    hu: 'هنغاريا',
    is: 'آيسلاندا',
    in: 'الهند',
    id: 'إندونيسيا',
    ir: 'إيران (جمهورية - الإسلامية)',
    iq: 'العراق',
    ie: 'آيرلندا',
    il: 'إسرائيل',
    it: 'إيطاليا',
    jm: 'جامايكا',
    jp: 'اليابان',
    jo: 'الأردن',
    kz: 'كازاخستان',
    ke: 'كينيا',
    ki: 'كيريباتي',
    kw: 'الكويت',
    kg: 'قيرغيزستان',
    la: 'جمهورية لاوس الديمقراطية الشعبية',
    lv: 'لاتفيا',
    lb: 'لبنان',
    ls: 'ليسوتو',
    lr: 'ليبريا',
    ly: 'ليبيا',
    li: 'ليختنشتاين',
    lt: 'ليتوانيا',
    lu: 'لكسمبرغ',
    mg: 'مدغشقر',
    mw: 'ملاوي',
    my: 'ماليزيا',
    mv: 'المالديف',
    ml: 'مالي',
    mt: 'مالطة',
    mh: 'جزر مارشال',
    mr: 'موريتانيا',
    mu: 'موريشيوس',
    mx: 'المكسيك',
    fm: 'ميكرونيزيا (ولايات - الموحدة)',
    mc: 'موناكو',
    mn: 'منغوليا',
    me: 'الجبل الأسود',
    ma: 'المغرب',
    mz: 'الموزامبيق',
    mm: 'ميانمار',
    na: 'ناميبيا',
    nr: 'ناورو',
    np: 'نيبال',
    nl: 'هولندا',
    nz: 'نيوزيلندا',
    ni: 'نيكاراغوا',
    ne: 'النيجر',
    ng: 'نيجيريا',
    mk: 'مقدونيا الشمالية',
    no: 'النرويج',
    om: 'عمان',
    pk: 'باكستان',
    pw: 'بالاو',
    ps: 'فلسطين',
    pa: 'بنما',
    pg: 'بابوا غينيا الجديدة',
    py: 'باراغواي',
    pe: 'بيرو',
    ph: 'الفلبين',
    pl: 'بولندا',
    pt: 'البرتغال',
    pr: 'بورتوريكو',
    qa: 'قطر',
    kr: 'جمهورية كوريا',
    md: 'جمهورية مولدوفا',
    ro: 'رومانيا',
    ru: 'الاتحاد الروسي',
    rw: 'رواندا',
    kn: 'سانت كيتس ونيفس',
    lc: 'سانت لوسيا',
    vc: 'سانت فنسنت وجزر غرينادين',
    ws: 'ساموا',
    sm: 'سان مارينو',
    st: 'سان تومي وبرينسيبي',
    sa: 'السعودية',
    sn: 'السنغال',
    rs: 'صربيا',
    sc: 'سيشيل',
    sl: 'سيراليون',
    sg: 'سنغافورة',
    sk: 'سلوفاكيا',
    si: 'سلوفينيا',
    sb: 'جزر سليمان',
    so: 'الصومال',
    za: 'جنوب أفريقيا',
    ss: 'جنوب السودان',
    es: 'إسبانيا',
    lk: 'سري لانكا',
    sd: 'السودان',
    sr: 'سورينام',
    se: 'السويد',
    ch: 'سويسرا',
    sy: 'سوريا',
    tj: 'طاجيكستان',
    th: 'تايلند',
    tl: 'تيمور - ليشتي',
    tg: 'توغو',
    to: 'تونغا',
    tt: 'ترينيداد وتوباغو',
    tn: 'تونس',
    tr: 'تركيا',
    tm: 'تركمانستان',
    tv: 'توفالو',
    ug: 'أوغندا',
    ua: 'أوكرانيا',
    ae: 'الإمارات العربية المتحدة',
    gb: 'المملكة المتحدة',
    tz: 'جمهورية تنزانيا المتحدة',
    us: 'الولايات المتحدة الأمريكية',
    uy: 'أوروغواي',
    uz: 'أوزبكستان',
    vu: 'فانواتو',
    ve: 'فنزويلا (جمهورية - البوليفارية)',
    vn: 'فيتنام',
    ye: 'اليمن',
    zm: 'زامبيا',
    zw: 'زمبابوي',
    'dt-ac': 'أكساي تشين',
    'dt-jk': 'جامو وكشمير',
    'dt-it': 'مثلث إليمي',
    gl: 'غرينلاند',
    tw: 'تايوان (المقاطعة الصينية)',
    hk: 'هونغ كونغ',
    mo: 'ماكاو',
    fk: 'جزر فوكلاند',
    eh: 'الصحراء الغربية'
  }
}
