import React, { useEffect, useState, lazy } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Snackbar, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import CancelIcon from '@material-ui/icons/Cancel'
import { clearStore } from './slices/dashboard.slice'
import { clearSnackbar } from './slices/snackbar.slice'
import { expiredMsg } from './utils/utils/constants'

const ErrorBoundary = lazy(() => import('./components/ErrorBoundary'))
const Dashboard = lazy(() => import('./pages/Dashboard'))
const LandingPage = lazy(() => import('./pages/LandingPage'))
const NotFound = lazy(() => import('./pages/NotFound'))
const PublicRoute = lazy(() => import('./PublicRoute'))

const Routes = () => {
  const dispatch = useDispatch()
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const { t } = useTranslation()

  const handleClose = () => {
    setSnackbarOpen(false)
  }

  const data = useSelector((state) => state.snackbar)
  useEffect(() => {
    if (data.message && !window.location.href.includes('parliament')) {
      setSnackbarOpen(true)
    } else {
      setSnackbarOpen(false)
    }

    if (expiredMsg.includes(data?.message)) {
      dispatch(clearStore())
    }
    setTimeout(function () {
      dispatch(clearSnackbar())
    }, 4000)
  }, [data])

  return (
    <ErrorBoundary>
      <Router basename={process.env.REACT_APP_BASE_PATH}>
        <Switch>
          <PublicRoute component={LandingPage} path='/' exact />
          <PublicRoute component={Dashboard} path={['/map', '/faq', '/parliament/:id']} exact />
          <Route path='*'>
            <NotFound />
          </Route>
        </Switch>
      </Router>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={handleClose}
        message={
          <>
            <IconButton
              className={`snackbar-${data.type}`}
              size='small'
              aria-label='close'
              color='inherit'
              onClick={handleClose}
            >
              {data.type === 'SUCCESS'
                ? (
                  <CheckCircleOutlineIcon fontSize='large' />
                  )
                : (
                  <CancelIcon fontSize='large' />
                  )}
            </IconButton>
            {t(data.message)}
          </>
        }
        action={
          <>
            <IconButton
              className='snackbar-action'
              size='small'
              aria-label='close'
              color='inherit'
              onClick={handleClose}
            >
              <CloseIcon fontSize='small' />
            </IconButton>
          </>
        }
      />
    </ErrorBoundary>
  )
}

export default Routes
