import React, { useEffect, Suspense } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import { I18nextProvider } from 'react-i18next'
import TagManager from 'react-gtm-module'
import store from './store'

import theme from './config/theme'
import GlobalTheme from './config/theme/globalTheme'
import i18n from './config/locales/i18n'
import useLocalStorage from './hooks/useLocalStorage'
import Routes from './Router'
import { Helmet } from 'react-helmet'
import { setDir } from './slices/lang.slice'
import { refreshLocalStorage } from './utils/utils'

function App () {
  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_ID
  }
  const [lang, setLang] = useLocalStorage('lang', { lang: 'en', dir: 'ltr' })
  const storeData = useSelector(state => state.lang)
  const dispatch = useDispatch()
  const [defaultTheme, setTheme] = React.useState(theme)

  useEffect(() => {
    refreshLocalStorage()
    setLang(lang || { lang: 'en', dir: 'ltr' })
    i18n.changeLanguage(lang.lang)
    dispatch(setDir({ textDirection: lang.dir, lang: lang.lang }))
    updateTheme()
  }, [])

  const updateTheme = () => {
    store.subscribe(() => {
      const newTheme = defaultTheme
      newTheme.textDirection = i18n.dir()
      setTheme(newTheme)
    })
  }

  useEffect(() => {
    TagManager.initialize(tagManagerArgs)
  }, [])
  return (
    <I18nextProvider i18n={i18n}>
      <Helmet htmlAttributes={{ lang: storeData.lang, dir: storeData.textDirection }} />
      <GlobalTheme />
      <ThemeProvider theme={defaultTheme}>
        <Suspense fallback=''>
          <Routes />
        </Suspense>
      </ThemeProvider>
    </I18nextProvider>
  )
}

export default App
