import { combineReducers } from '@reduxjs/toolkit'

import snackbarReducer from '../slices/snackbar.slice'
import dashboardReducer from '../slices/dashboard.slice'
import faqReducer from '../slices/faq.slice'
import countryDetailsReducer from '../slices/countryDetails.slice'
import landingPageReducer from '../slices/landingPage.slice'
import langReducer from '../slices/lang.slice'

const reducerList = {
  snackbar: snackbarReducer,
  dashboard: dashboardReducer,
  faq: faqReducer,
  countryDetails: countryDetailsReducer,
  landingPage: landingPageReducer,
  lang: langReducer
}

export default combineReducers(reducerList)
