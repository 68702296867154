const lang = JSON.parse(window.localStorage.getItem('lang'))

const theme = {
  colors: {
    primaryMain: '#005f9a',
    primaryContrast: '#fff',
    primaryBorder: '#2c3249',
    primaryBg: '#181c2d',
    grey: '#999',
    normalLink: '#b0b0b0',
    modalText: '#797979',
    modalHeading: '#575757',
    listText: '#889ccc',
    lightBorder: '#2d2b2b',
    secondaryMain: '#20b1e8',
    white: '#fff',
    inputBackground: '#efefef',
    red: '#ff0000',
    black: '#000',
    background: '#1b1b1b',
    warning: '#ffff00',
    paginationBackground: '#181c2d',
    paginationColor: '#fff',
    secondaryBlack: '#747678',
    detailsBg: '#f8f8f8',
    border: '#80808059',
    lightBlue: '#00aabe',
    contentTag: '#e98300',
    loaderBg: '#fefefe',
    linkColor: '#505050',
    linkBg: '#ccc',
    hoverBg: '#ff4783'
  },
  fontSizes: {
    small: '1em',
    medium: '2em',
    large: '3em'
  },
  background: {
    logo: 'linear-gradient(164deg, #005f9a 13%, #0073a4 87%)',
    copy: 'linear-gradient(164deg, #109cb7 13%, #1faabe 87%)'
  },
  textDirection: lang?.dir || 'ltr'
}

export default theme
