export default {
  Edit: 'Éditer',
  SavetoReload: 'et enregistrer pour recharger',
  Name: 'Nom',
  common: {
    save: 'Sauvegarder',
    fieldRequiredMessage: 'Ce champ est obligatoire',
    invalidEmail: 'Adresse e-mail invalide',
    loading: 'Loading',
    emailValidationMessage: 'Veuillez entrer une adresse e-mail valide',
    passwordMatch: 'les mots de passe doivent correspondre',
    submit: 'SOUMETTRE',
    passwordValidation: 'Le mot de passe doit avoir au moins un caractère majuscule, un caractère minuscule, un chiffre, un caractère spécial et doit contenir au moins 8 caractères',
    subscribe: "Abonnez-vous à la newsletter générale de l'UIP",
    cancel: 'Annuler',
    update: 'Mise à jour',
    emailError: 'L\'e-mail principal et l\'e-mail secondaire doivent être différents\'',
    nameValidation: 'Veuillez entrer un nom valide',
    passwordLength: 'Le mot de passe doit contenir au moins 8 caractères'
  },
  login: {
    heading: 'S\'identifier',
    email: 'Adresse e-mail',
    password: 'Mot de passe',
    forgot: 'Mot de passe oublié?',
    new: 'Nouvel utilisateur?'
  },
  register: {
    heading: "S'inscrire",
    haveAccount: 'Vous avez déjà un compte?',
    login: "S'IDENTIFIER",
    upload: 'Photo de profil'
  },
  forgot: {
    heading: 'Mot de passe oublié',
    content: 'Entrez votre adresse e-mail ci-dessous et nous vous enverrons des instructions afin de changer votre mot de passe.',
    link: 'ACCÉDER À LA CONNEXION'
  },
  reset: {
    heading: 'Réinitialiser le mot de passe'
  },
  upload: {
    upload: 'Télécharger la photo',
    change: 'Modifier la photo de profil',
    remove: 'Retirer',
    sizeError: 'Les images téléchargées doivent être inférieures à 2 Mo',
    maxSize: 'Max size allowed is'
  },
  profile: {
    heading: 'Profil',
    fullName: 'Nom complet',
    edit: 'Modifier le profil',
    delete: 'Supprimer le compte',
    currentPass: 'Mot de passe actuel',
    newPass: 'Nouveau mot de passe',
    confPass: 'Confirmer le nouveau mot de passe'
  },
  mapDisclaimer: {
    open: 'Clause de non-responsabilité : les appellations employées dans la présente carte et la présentation des données qui y figurent n’impliquent de la part de l’UIP aucune prise de position quant au statut juridique des pays, territoires, villes ou zones, ou de leurs autorités, ni quant au tracé de leurs frontières ou limites.',
    close: 'Note'
  },
  Upload: 'Télécharger une photo de profil',
  Select: 'Sélectionner',
  Email: 'Adresse e-mail',
  SecondaryEmail: 'Adresse e-mail secondaire (facultatif)',
  Copyright: "© Droit d'auteur UIP",
  IPUMembershipCategory: "Catégorie de membres de l'UIP",
  ConfirmPassword: 'Confirmez le mot de passe',
  Login: "S'IDENTIFIER",
  Register: "S'INSCRIRE",
  Profile: 'Profil',
  Logout: 'Se déconnecter',
  mapInfo: "Pour rester informé de toutes les informations concernant l'UIP, abonnez-vous à notre newsletter",
  Map: 'Carte',
  Faq: 'FAQ',
  Secondarymail: 'Adresse e-mail secondaire',
  Password: 'Mot de passe',
  ChangePassword: 'Changer le mot de passe',
  1000: 'Votre session est expirée, veuillez vous connecter pour continuer.',
  1001: "L'adresse e-mail principale n'est pas valide.",
  1002: "L'e-mail secondaire n'est pas valide.",
  1003: 'Le mot de passe ne correspond pas.',
  1004: 'La longueur minimale du mot de passe doit être de 8 caractères.',
  1005: 'Le mot de passe doit contenir au moins un caractère spécial.',
  1006: 'Le mot de passe doit contenir au moins une lettre majuscule.',
  1007: 'Le mot de passe doit contenir au moins une lettre minuscule.',
  1008: 'Le mot de passe doit contenir au moins un chiffre.',
  1009: "La catégorie de membres de l'UIP n'est pas valide.",
  1010: "L'image avatar n'a pas pu être traitée.",
  1011: 'Impossible de lire le fichier image.',
  1012: 'E-mail déjà enregistré.',
  1013: "L'e-mail principal ne peut pas être l'e-mail secondaire de l'utilisateur existant.",
  1014: "L'e-mail secondaire a déjà été utilisé.",
  1015: 'Les données de l’utilisateur ont été  mises à jour avec succès.',
  1016: "L'utilisateur n'existe pas.",
  1017: 'Nous avons envoyé votre lien de réinitialisation de mot de passe!.',
  1018: "Impossible d'envoyer l'e-mail de réinitialisation du mot de passe.",
  1019: "L'e-mail personnel a déjà été enregistré.",
  1020: 'Le mot de passe a été mis à jour avec succès. Vous serez bientôt déconnecté.',
  1021: "L'ancien mot de passe est incorrect.",
  1022: 'Utilisateur invalide.',
  1023: 'Le lien de réinitialisation du mot de passe a expiré!.',
  1024: 'Le mot de passe a été mis à jour avec succès.',
  1025: 'Lien de réinitialisation du mot de passe non valide.',
  1026: 'Succès',
  1027: 'Le compte d’utilisateur a été supprimé avec succès.',
  1028: 'Votre compte n\'est pas actif.\'',
  1033: "Le compte d’utilisateur n'a pas été activé ou est bloqué.",
  1034: 'E-mail ou mot de passe incorrect.',
  1035: 'Trop de tentatives de connexion échouées à partir de votre adresse IP. Cette adresse IP est temporairement bloquée.',
  1036: "Cet itinéraire n'est accessible qu'aux utilisateurs anonymes.",
  1037: "Inscription réussi. Veuillez attendre l'approbation de l'administrateur.",
  1039: "Le site web de l'UIP est actuellement en cours de maintenance. Nous devrions être de retour bientôt. Merci de votre patience.",
  1038: 'Mot de passe mis à jour.Vous serez bientôt déconnecté.',
  delete: {
    title: 'Supprimer le compte?',
    content: 'Voulez-vous vraiment supprimer ce compte?',
    delete: 'Supprimer',
    cancel: 'Annuler',
    picContent: 'Voulez-vous vraiment retirer  cette image?',
    picTitle: "Retirer  l'image?",
    remove: 'Retirer'
  },
  apologies: "Désolé. Nous n'avons pas trouvé la page que vous recherchiez.",
  goHome: 'ACCÉDER À LA PAGE D’ACCUEIL',
  goLogin: 'ACCÉDER À LA CONNEXION',
  faq: {
    header: 'Foire aux questions',
    noresult: 'Aucun résultat trouvé!',
    nodata: 'Aucune donnée disponible!'
  },
  countryDetails: {
    countryCode: 'Code de pays',
    assembly: 'Assemblée',
    parliament: 'Parlement',
    senate: 'Sénat',
    nationalLegislation: 'Législation nationale sur LCT/EV',
    agreements: 'Accords signés avec l\'ONU',
    workWithNGOs: 'Travailler avec des ONG et/ou des OSC',
    conferences: 'Conférences organisées',
    otherInformation: 'Les autres informations',
    committee_in_charge: 'Comité en charge',
    president_of_committee: 'Président du comité',
    vice_president_of_committee: 'Vice-président du comité',
    secretary_of_committee: 'Secrétaire du comité',
    otherLegislation: 'Autres législations pertinentes',
    linkWithLeg: 'Lien avec la législation sur LCT/EV'
  },
  map: {
    linkToDetails: "Comité de lutte contre le terrorisme et l'extrémisme violent",
    eh: 'Territoire non autonome',
    fk: "Un différnd existe entre les gouvernements de l'Argentine et du Royaume-Uni concernant la souveraineté su les îles Falkland (îles Malouines)",
    'dt-ac': "Territoire contesté par l'Inde et la Chine.",
    'dt-jk': "Remarque: Cette zone représente approximativement la ligne de contrôle du Jammu-et-Cachemire convenue entre l'Inde et le Pakistan. Le statut defenitif du Jammu-et-Cachemire n'a pas encore été convenu par les parties",
    'dt-it': 'Territoire contesté entre le Soudan du Sud et le Kenya',
    sd: "La frontière définitive entre la République du Soudan et la République du Soudan du Sud n'a pas encore été déterminée",
    ss: "La frontière définitive entre la République du Soudan et la République du Soudan du Sud n'a pas encore été déterminée",
    pr: 'États-Unis',
    tw: '', // Don't show any notes for taiwan
    ipuMember: "Membre de l'UIP",
    nonIpu: "Non-membre de l'UIP",
    provinceOfChina: ' (Province de Chine)'
  },
  errorBoundary: {
    heading: 'Un problème est survenu!',
    message: 'Vous pouvez réessayer maintenant ou réessayer plus tard.',
    retry: 'RÉESSAYER'
  },
  countries: {
    af: 'Afghanistan',
    al: 'Albanie',
    dz: 'Algérie',
    ad: 'Andorre',
    ao: 'Angola',
    ag: 'Antigua-et-Barbuda',
    ar: 'Argentine',
    am: 'Arménie',
    au: 'Australie',
    at: 'Autriche',
    az: 'Azerbaïdjan',
    bs: 'Bahamas',
    bh: 'Bahreïn',
    bd: 'Bangladesh',
    bb: 'Barbade',
    by: 'Bélarus',
    be: 'Belgique',
    bz: 'Belize',
    bj: 'Bénin',
    bt: 'Bhoutan',
    bo: 'Bolivie (État plurinational de)',
    ba: 'Bosnie-Herzégovine',
    bw: 'Botswana',
    br: 'Brésil',
    bn: 'Brunéi Darussalam',
    bg: 'Bulgarie',
    bf: 'Burkina Faso',
    bi: 'Burundi',
    cv: 'Cabo Verde',
    kh: 'Cambodge',
    cm: 'Cameroun',
    ca: 'Canada',
    cf: 'République centrafricaine',
    td: 'Tchad',
    cl: 'Chili',
    cn: 'Chine',
    co: 'Colombie',
    km: 'Comores',
    cg: 'Congo',
    cr: 'Costa Rica',
    ci: "Côte d'Ivoire",
    hr: 'Croatie',
    cu: 'Cuba',
    cy: 'Chypre',
    cz: 'République tchèque',
    kp: 'République populaire démocratique de Corée',
    cd: 'République démocratique du Congo',
    dk: 'Danemark',
    dj: 'Djibouti',
    dm: 'Dominique',
    do: 'République dominicaine',
    ec: 'Équateur',
    eg: 'Égypte',
    sv: 'El Salvador',
    gq: 'Guinée équatoriale',
    er: 'Érythrée',
    ee: 'Estonie',
    sz: 'Eswatini',
    et: 'Éthiopie',
    fj: 'Fidji',
    fi: 'Finlande',
    fr: 'France',
    ga: 'Gabon',
    gm: 'Gambie',
    ge: 'Géorgie',
    de: 'Allemagne',
    gh: 'Ghana',
    gr: 'Grèce',
    gd: 'Grenade',
    gt: 'Guatemala',
    gn: 'Guinée',
    gw: 'Guinée-Bissau',
    gy: 'Guyana',
    ht: 'Haïti',
    hn: 'Honduras',
    hu: 'Hongrie',
    is: 'Islande',
    in: 'Inde',
    id: 'Indonésie',
    ir: "Iran (République islamique d')",
    iq: 'Iraq',
    ie: 'Irlande',
    il: 'Israël',
    it: 'Italie',
    jm: 'Jamaïque',
    jp: 'Japon',
    jo: 'Jordanie',
    kz: 'Kazakhstan',
    ke: 'Kenya',
    ki: 'Kiribati',
    kw: 'Koweït',
    kg: 'Kirghizistan',
    la: 'République démocratique populaire lao',
    lv: 'Lettonie',
    lb: 'Liban',
    ls: 'Lesotho',
    lr: 'Libéria',
    ly: 'Libye',
    li: 'Liechtenstein',
    lt: 'Lituanie',
    lu: 'Luxembourg',
    mg: 'Madagascar',
    mw: 'Malawi',
    my: 'Malaisie',
    mv: 'Maldives',
    ml: 'Mali',
    mt: 'Malte',
    mh: 'Îles Marshall',
    mr: 'Mauritanie',
    mu: 'Maurice',
    mx: 'Mexique',
    fm: 'Micronésie (États fédérés de)',
    mc: 'Monaco',
    mn: 'Mongolie',
    me: 'Monténégro',
    ma: 'Maroc',
    mz: 'Mozambique',
    mm: 'Myanmar',
    na: 'Namibie',
    nr: 'Nauru',
    np: 'Népal',
    nl: 'Pays-Bas',
    nz: 'Nouvelle-Zélande',
    ni: 'Nicaragua',
    ne: 'Niger',
    ng: 'Nigéria',
    mk: 'Macédoine du Nord',
    no: 'Norvège',
    om: 'Oman',
    pk: 'Pakistan',
    pw: 'Palaos',
    ps: 'Palestine',
    pa: 'Panama',
    pg: 'Papouasie-Nouvelle-Guinée',
    py: 'Paraguay',
    pe: 'Pérou',
    ph: 'Philippines',
    pl: 'Pologne',
    pt: 'Portugal',
    pr: 'Porto Rico',
    qa: 'Qatar',
    kr: 'République de Corée',
    md: 'République de Moldova',
    ro: 'Roumanie',
    ru: 'Fédération de Russie',
    rw: 'Rwanda',
    kn: 'Saint-Kitts-et-Nevis',
    lc: 'Sainte-Lucie',
    vc: 'Saint-Vincent-et-les-Grenadines',
    ws: 'Samoa',
    sm: 'Saint-Marin',
    st: 'Sao Tomé-et-Principe',
    sa: 'Arabie saoudite',
    sn: 'Sénégal',
    rs: 'Serbie',
    sc: 'Seychelles',
    sl: 'Sierra Leone',
    sg: 'Singapour',
    sk: 'Slovaquie',
    si: 'Slovénie',
    sb: 'Îles Salomon',
    so: 'Somalie',
    za: 'Afrique du Sud',
    ss: 'Soudan du Sud',
    es: 'Espagne',
    lk: 'Sri Lanka',
    sd: 'Soudan',
    sr: 'Suriname',
    se: 'Suède',
    ch: 'Suisse',
    sy: 'République arabe syrienne',
    tj: 'Tadjikistan',
    th: 'Thaïlande',
    tl: 'Timor-Leste',
    tg: 'Togo',
    to: 'Tonga',
    tt: 'Trinité-et-Tobago',
    tn: 'Tunisie',
    tr: 'Turquie',
    tm: 'Turkménistan',
    tv: 'Tuvalu',
    ug: 'Ouganda',
    ua: 'Ukraine',
    ae: 'Émirats arabes unis',
    gb: 'Royaume-Uni',
    tz: 'République-Unie de Tanzanie',
    us: 'États-Unis',
    uy: 'Uruguay',
    uz: 'Ouzbékistan',
    vu: 'Vanuatu',
    ve: 'Venezuela (République bolivarienne du)',
    vn: 'Viêt Nam',
    ye: 'Yémen',
    zm: 'Zambie',
    zw: 'Zimbabwe',
    'dt-ac': 'Aksai Chin',
    'dt-jk': 'Jammu-et-Cachemire',
    'dt-it': 'Triangle d\'Ilemi',
    gl: 'Groenland',
    tw: 'Taiwan (Province chinoise de Taiwan)',
    hk: 'Hong Kong',
    mo: 'Macau',
    fk: 'Îles Falkland (îles Malouines)',
    eh: 'Sahara occidental'
  }
}
