import axios from 'axios'

import { checkBackendUrl, onRequest, onRequestError, onResponse, onResponseError } from './interceptors'

// Use direct URL if its a prod, otherwise, use proxy.
const baseURL = checkBackendUrl()
const api = axios.create({ baseURL: baseURL })

// Add a request interceptor
api.interceptors.request.use(onRequest, onRequestError)

// Add a response interceptor
api.interceptors.response.use(onResponse, onResponseError)

export const API_LOGIN = 'user/login?_format=json'
export const API_REGISTER = 'user/signup?_format=json'
export const API_FORGOT_PASSWORD = 'user/forgot-password?_format=json'
export const API_RESET_PASSWORD = 'user/reset-password-by-link?_format=json'
export const API_MEMBERSHIP_CATEGORY = 'get/ipu_membership_category?_format=json'
export const API_VIEW_PROFILE = 'user/view-profile?_format=json'
export const API_UPDATE_PROFILE = 'user/edit-profile?_format=json'
export const API_CHANGE_PASSWORD = 'user/change-password?_format=json'
export const API_DELETE_ACCOUNT = 'delete-account?_format=json'
export const API_FETCH_FAQ = 'list/faqs?_format=json'
export const API_FETCH_COUNTRY_DETAILS = 'country/{countryCode}?_format=json'
export const API_FETCH_COUNTRIES = 'countries?_format=json'
export const PROFILE_PIC = 'user/profile-image/avatar'
export const LANDING_PAGE = 'landing-page?_format=json'
export const LANDING_LOGGED_PAGE = 'home-page?_format=json'
export default api
