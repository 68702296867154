export default {
  Edit: 'Edit',
  SavetoReload: 'and save to reload',
  Name: 'Name',
  common: {
    save: 'Save',
    fieldRequiredMessage: 'This field is required',
    invalidEmail: 'Invalid email',
    loading: 'Loading',
    emailValidationMessage: 'Please enter a valid e-mail',
    passwordMatch: 'Passwords must match',
    submit: 'SUBMIT',
    passwordValidation: 'Password must have at least an uppercase character, a lowercase character, a digit, a special character and should be at least 8 characters long',
    subscribe: 'Subscribe to the IPU general newsletter',
    cancel: 'Cancel',
    update: 'Update',
    emailError: 'Primary email and secondary email should be different',
    nameValidation: 'Please enter a valid name',
    passwordLength: 'Password must be at least 8 characters long'
  },
  login: {
    heading: 'Login',
    email: 'Email Address',
    password: 'Password',
    forgot: 'Forgot Password?',
    new: 'New User?'
  },
  register: {
    heading: 'Register',
    haveAccount: 'Already have an account?',
    login: 'LOGIN',
    upload: 'Profile Photo'
  },
  forgot: {
    heading: 'Forgot Password',
    content: 'Enter your email address below and we will send you instructions on how to change your password.',
    link: 'GO TO LOGIN'
  },
  reset: {
    heading: 'Reset Password'
  },
  upload: {
    upload: 'Upload Photo',
    change: 'Change Profile Picture',
    remove: 'Remove',
    sizeError: 'Pictures uploaded should be less than 2MB',
    maxSize: 'Max size allowed is'
  },
  profile: {
    heading: 'Profile',
    fullName: 'Full Name',
    edit: 'Edit Profile',
    delete: 'Delete Account',
    currentPass: 'Current Password',
    newPass: 'New Password',
    confPass: 'Confirm New Password'
  },
  mapDisclaimer: {
    open: 'The designations employed and the presentation of material on this map do not imply the expression of any opinion whatsoever on the part of the IPU concerning the legal status of any country, territory, city or area or of its authorities, or concerning the delimitations of its frontiers or boundaries',
    close: 'Disclaimer'
  },
  Upload: 'Upload Profile Photo',
  Select: 'Select',
  Email: 'Email address',
  SecondaryEmail: 'Secondary email address (Optional)',
  Copyright: '© Copyright IPU',
  IPUMembershipCategory: 'IPU Membership Category',
  ConfirmPassword: 'Confirm Password',
  Login: 'LOGIN',
  Register: 'REGISTER',
  Profile: 'Profile',
  Logout: 'Logout',
  mapInfo: 'To stay up-to-date on all information regarding IPU subscribe to our newsletter',
  Map: 'Map',
  Faq: 'FAQ',
  Secondarymail: 'Secondary email address',
  Password: 'Password',
  ChangePassword: 'Change Password',
  1000: 'Your session is expired. Please login to continue.',
  1001: 'Primary email is not valid.',
  1002: 'Secondary email is not valid.',
  1003: 'The passwords do not match.',
  1004: 'The password must have a minimum length of 8 characters.',
  1005: 'The password must contain atleast one special character.',
  1006: 'The password must contain atleast one uppercase letter.',
  1007: 'The password must contain atleast one lowercase letter.',
  1008: 'The password must contain atleast one digit.',
  1009: 'The IPU membership category is not valid.',
  1010: 'Avatar image could not be processed.',
  1011: 'Could not read the image file.',
  1012: 'Already registered email.',
  1013: 'Primary email cannot be the secondary email of existing user.',
  1014: 'Secondary email has already been used.',
  1015: 'User data updated successfully.',
  1016: 'User does not exist.',
  1017: 'We have emailed your password reset link!',
  1018: 'Could not send the password reset email.',
  1019: 'Personal email has already been registered.',
  1020: 'Password updated successfully. You will be logged out shortly.',
  1021: 'Old password is incorrect.',
  1022: 'Invalid User.',
  1023: 'The reset password link has expired!',
  1024: 'Password updated successfully.',
  1025: 'Invalid reset password link.',
  1026: 'Success',
  1027: 'User account successfully deleted.',
  1028: 'Your account is not active.',
  1033: 'The user account has not been activated or is blocked.',
  1034: 'Email or Password is incorrect.',
  1035: 'Too many failed login attempts from your IP address. This IP address is temporarily blocked.',
  1036: 'This route can only be accessed by anonymous users.',
  1037: 'Registration successful. Please wait for admin approval.',
  1039: 'IPU website is currently under maintenance. We will be back shortly. Thank you for your patience.',
  1038: 'Password updated. You will be logged out shortly.',
  delete: {
    title: 'Delete Account?',
    content: 'Are you sure you want to delete this account?',
    delete: 'Delete',
    cancel: 'Cancel',
    picContent: 'Are you sure you want to remove this picture?',
    picTitle: 'Remove Picture?',
    remove: 'Remove'
  },
  apologies: 'Sorry. We couldn’t find the page you were looking for.',
  goHome: 'GO TO HOME',
  goLogin: 'GO TO LOGIN',
  faq: {
    header: 'Frequently Asked Questions',
    noresult: 'No results found!',
    nodata: 'No data found!'
  },
  countryDetails: {
    countryCode: 'Country Code',
    assembly: 'Assembly',
    parliament: 'Parliament',
    senate: 'Senate',
    nationalLegislation: 'National Legislation on CT/VE',
    agreements: 'Agreements Signed with UN',
    workWithNGOs: 'Work with NGOs and/or CSOs',
    conferences: 'Conferences Hosted',
    otherInformation: 'Other Information',
    committee_in_charge: 'Committee in Charge',
    president_of_committee: 'President of Committee',
    vice_president_of_committee: 'Vice President of Committee',
    secretary_of_committee: 'Secretary of Committee',
    otherLegislation: 'Other Relevant Legislations',
    linkWithLeg: 'Link with legislation on CT/VE'
  },
  map: {
    linkToDetails: 'Committee to counter-terrorism and violent extremism',
    eh: 'Non-Self-Governing-Territory',
    fk: 'A dispute exists between the Governments of Argentina and the United Kingdom concerning sovereignty over the Falkland Islands (Malvinas)',
    'dt-ac': 'Disputed territory between India and China',
    'dt-jk': 'Note: This area represents approximately the Line of Control in Jammu and Kashmir agreed upon by India and Pakistan. The final status of Jammu and Kasmir has not yet been agreed upon by the parties.',
    'dt-it': 'Disputed territory between South Sudan and Kenya',
    sd: 'Final boundary between the Republic of Sudan and the Republic of South Sudan has not yet been determined',
    ss: 'Final boundary between the Republic of Sudan and the Republic of South Sudan has not yet been determined',
    pr: 'United States of America',
    tw: '', // Don't show any notes for taiwan
    ipuMember: 'IPU member',
    nonIpu: 'Not an IPU member',
    provinceOfChina: ' (Province of China)'
  },
  errorBoundary: {
    heading: 'Something went wrong!',
    message: 'You can either retry now or try again later.',
    retry: 'RETRY'
  },
  countries: {
    af: 'Afghanistan',
    al: 'Albania',
    dz: 'Algeria',
    ad: 'Andorra',
    ao: 'Angola',
    ag: 'Antigua and Barbuda',
    ar: 'Argentina',
    am: 'Armenia',
    au: 'Australia',
    at: 'Austria',
    az: 'Azerbaijan',
    bs: 'Bahamas',
    bh: 'Bahrain',
    bd: 'Bangladesh',
    bb: 'Barbados',
    by: 'Belarus',
    be: 'Belgium',
    bz: 'Belize',
    bj: 'Benin',
    bt: 'Bhutan',
    bo: 'Bolivia (Plurinational State of)',
    ba: 'Bosnia and Herzegovina',
    bw: 'Botswana',
    br: 'Brazil',
    bn: 'Brunei Darussalam',
    bg: 'Bulgaria',
    bf: 'Burkina Faso',
    bi: 'Burundi',
    cv: 'Cabo Verde',
    kh: 'Cambodia',
    cm: 'Cameroon',
    ca: 'Canada',
    cf: 'Central African Republic',
    td: 'Chad',
    cl: 'Chile',
    cn: 'China',
    co: 'Colombia',
    km: 'Comoros',
    cg: 'Congo',
    cr: 'Costa Rica',
    ci: "Côte d'Ivoire",
    hr: 'Croatia',
    cu: 'Cuba',
    cy: 'Cyprus',
    cz: 'Czech Republic',
    kp: "Democratic People's Republic of Korea",
    cd: 'Democratic Republic of the Congo',
    dk: 'Denmark',
    dj: 'Djibouti',
    dm: 'Dominica',
    do: 'Dominican Republic',
    ec: 'Ecuador',
    eg: 'Egypt',
    sv: 'El Salvador',
    gq: 'Equatorial Guinea',
    er: 'Eritrea',
    ee: 'Estonia',
    sz: 'Eswatini',
    et: 'Ethiopia',
    fj: 'Fiji',
    fi: 'Finland',
    fr: 'France',
    ga: 'Gabon',
    gm: 'Gambia (The)',
    ge: 'Georgia',
    de: 'Germany',
    gh: 'Ghana',
    gr: 'Greece',
    gd: 'Grenada',
    gt: 'Guatemala',
    gn: 'Guinea',
    gw: 'Guinea-Bissau',
    gy: 'Guyana',
    ht: 'Haiti',
    hn: 'Honduras',
    hu: 'Hungary',
    is: 'Iceland',
    in: 'India',
    id: 'Indonesia',
    ir: 'Iran (Islamic Republic of)',
    iq: 'Iraq',
    ie: 'Ireland',
    il: 'Israel',
    it: 'Italy',
    jm: 'Jamaica',
    jp: 'Japan',
    jo: 'Jordan',
    kz: 'Kazakhstan',
    ke: 'Kenya',
    ki: 'Kiribati',
    kw: 'Kuwait',
    kg: 'Kyrgyzstan',
    la: "Lao People's Democratic Republic",
    lv: 'Latvia',
    lb: 'Lebanon',
    ls: 'Lesotho',
    lr: 'Liberia',
    ly: 'Libya',
    li: 'Liechtenstein',
    lt: 'Lithuania',
    lu: 'Luxembourg',
    mg: 'Madagascar',
    mw: 'Malawi',
    my: 'Malaysia',
    mv: 'Maldives',
    ml: 'Mali',
    mt: 'Malta',
    mh: 'Marshall Islands',
    mr: 'Mauritania',
    mu: 'Mauritius',
    mx: 'Mexico',
    fm: 'Micronesia (Federated States of)',
    mc: 'Monaco',
    mn: 'Mongolia',
    me: 'Montenegro',
    ma: 'Morocco',
    mz: 'Mozambique',
    mm: 'Myanmar',
    na: 'Namibia',
    nr: 'Nauru',
    np: 'Nepal',
    nl: 'Netherlands',
    nz: 'New Zealand',
    ni: 'Nicaragua',
    ne: 'Niger',
    ng: 'Nigeria',
    mk: 'North Macedonia',
    no: 'Norway',
    om: 'Oman',
    pk: 'Pakistan',
    pw: 'Palau',
    ps: 'Palestine',
    pa: 'Panama',
    pg: 'Papua New Guinea',
    py: 'Paraguay',
    pe: 'Peru',
    ph: 'Philippines',
    pl: 'Poland',
    pt: 'Portugal',
    pr: 'Puerto Rico',
    qa: 'Qatar',
    kr: 'Republic of Korea',
    md: 'Republic of Moldova',
    ro: 'Romania',
    ru: 'Russian Federation',
    rw: 'Rwanda',
    kn: 'Saint Kitts and Nevis',
    lc: 'Saint Lucia',
    vc: 'Saint Vincent and the Grenadines',
    ws: 'Samoa',
    sm: 'San Marino',
    st: 'Sao Tome and Principe',
    sa: 'Saudi Arabia',
    sn: 'Senegal',
    rs: 'Serbia',
    sc: 'Seychelles',
    sl: 'Sierra Leone',
    sg: 'Singapore',
    sk: 'Slovakia',
    si: 'Slovenia',
    sb: 'Solomon Islands',
    so: 'Somalia',
    za: 'South Africa',
    ss: 'South Sudan',
    es: 'Spain',
    lk: 'Sri Lanka',
    sd: 'Sudan',
    sr: 'Suriname',
    se: 'Sweden',
    ch: 'Switzerland',
    sy: 'Syrian Arab Republic',
    tj: 'Tajikistan',
    th: 'Thailand',
    tl: 'Timor-Leste',
    tg: 'Togo',
    to: 'Tonga',
    tt: 'Trinidad and Tobago',
    tn: 'Tunisia',
    tr: 'Turkey',
    tm: 'Turkmenistan',
    tv: 'Tuvalu',
    ug: 'Uganda',
    ua: 'Ukraine',
    ae: 'United Arab Emirates',
    gb: 'United Kingdom',
    tz: 'United Republic of Tanzania',
    us: 'United States of America',
    uy: 'Uruguay',
    uz: 'Uzbekistan',
    vu: 'Vanuatu',
    ve: 'Venezuela (Bolivarian Republic of)',
    vn: 'Viet Nam',
    ye: 'Yemen',
    zm: 'Zambia',
    zw: 'Zimbabwe',
    'dt-ac': 'Aksai Chin',
    'dt-jk': 'Jammu and Kashmir',
    'dt-it': 'Ilemi Triangle',
    gl: 'Greenland',
    tw: 'Taiwan (Province of China)',
    hk: 'Hong Kong',
    mo: 'Macau',
    fk: 'Falkland Islands',
    eh: 'Western Sahara'
  }
}
