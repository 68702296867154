import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  message: '',
  type: '',
  timestamp: -1,
  status: ''
}

const snackbar = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    showSnackbar (state, action) {
      state.message = action.payload.message
      state.type = action.payload.type
      state.timestamp = new Date().getTime()
      state.status = action.payload.status
    },
    clearSnackbar (state, action) {
      state.message = ''
      state.type = ''
      state.timestamp = -1
      state.status = ''
    }
  }
})

export const { showSnackbar, clearSnackbar } = snackbar.actions
export default snackbar.reducer
