import { createSlice } from '@reduxjs/toolkit'
import api, { API_FETCH_COUNTRY_DETAILS } from '../utils/axios'

const initialState = {
  loading: false,
  details: {}
}

const countryDetails = createSlice({
  name: 'countryDetails',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setCountryDetails: (state, action) => {
      state.details = action.payload
    },
    clearCountryDetails: (state) => {
      state.details = initialState.details
    }
  }
})

export const {
  setLoading,
  setCountryDetails,
  clearCountryDetails
} = countryDetails.actions

export default countryDetails.reducer

export const fetchCountryDetails = (countryCode) => async (dispatch) => {
  try {
    dispatch(setLoading(true))
    const apiResponse = await api.get(
      API_FETCH_COUNTRY_DETAILS.replace('{countryCode}', countryCode)
    )
    if (apiResponse) {
      const data = apiResponse.data
      const committee = data.committee
      if (committee) {
        committee.forEach((v) => { // generate id for each item
          v.id = Math.random().toString(36).substr(2, 9)
        })
      }
      dispatch(setCountryDetails(data))
    }
    dispatch(setLoading(false))
  } catch (error) {
    dispatch(setLoading(false))
  }
}
