import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`

#root {
  height: 100%;
}

// font styles and configuration
font-family: Roboto;
`;
export default GlobalStyle;
