export const phoneRegExp = /\d{3}-\d{3}-\d{4}/
// eslint-disable-next-line
export const emailRegExp = /^([a-zA-Z0-9_\-\+\.]+)@([a-zA-Z0-9_\-\+\.]+)\.([a-zA-Z]{2,5})$/;
// eslint-disable-next-line
export const passwordRegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~])[A-Za-z\d`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]{8,}$/;
// eslint-disable-next-line
export const nameRegExp = /^[a-zA-Z ]{2,30}$/;

// Other constants
export const newsletterSubscriptionUrl = 'https://ipu.us8.list-manage.com/subscribe?u=1ad826db47d771b01de9c3a2a&id=d1ccee59b3'

export const serverUnavailableCodes = [500, 503]

export const amChartsLicense = 'MP260105481'

export const encKey = 'sA167#4$dS4'

export const expiredMsg = [
  'Votre session est expirée, veuillez vous connecter pour continuer',
  'Your session is expired, Please login to continue'
]
