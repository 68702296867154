/* global localStorage */

import AES from 'crypto-js/aes'
import CryptoJS from 'crypto-js'

import ukFlag from '../../assets/ukflag.svg'
import frFlag from '../../assets/frflag.svg'
import languageBlack from '../../assets/language_black.png'
// import engLogo from '../../assets/engLogo.png'
// import frenchLogo from '../../assets/frenchLogo.png'
import { encKey } from './constants'

export const setLocalStore = (cname, cvalue) => {
  if (cvalue) {
    const encryptedData = AES.encrypt(JSON.stringify(cvalue), encKey)
    localStorage.setItem(cname, encryptedData)
  }
}

export const getLocalStore = (cname) => {
  if (cname === 'i18nextLng') return localStorage.getItem(cname)
  let decryptedData = null
  const bytes =
    localStorage.getItem(cname) &&
    AES.decrypt(localStorage.getItem(cname), encKey)
  decryptedData = bytes && JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
  return decryptedData
}

export const objectArrayFromField = (list, field, value) => {
  return list?.length
    ? list.map((item) => {
        return {
          label: item[field],
          value: item[value]
        }
      })
    : []
}

export function createMarkup (content) {
  return {
    __html: content
  }
}

export const languageMenu = [
  {
    label: <div><img className='language-img' src={languageBlack} />EN</div>,
    value: 'en',
    img: ukFlag
  },
  {
    label: <div><img className='language-img' src={languageBlack} />FR</div>,
    value: 'fr',
    img: frFlag
  },
  {
    label: <div><img className='language-img' src={languageBlack} />AR</div>,
    value: 'ar',
    img: ukFlag
  }
]

export const refreshLocalStorage = () => {
  const refreshed = window.localStorage.getItem('refreshed')
  if (!JSON.parse(refreshed)) {
    window.localStorage.clear()
    window.localStorage.setItem('refreshed', 'true')
  }
}
