/* global localStorage */
import reduxStore from '../../store'
import { showSnackbar } from '../../slices/snackbar.slice'

// Add a request interceptor
export function onRequest (config) {
  config.headers = {
    ...config.headers,
    'IPU-Platform': 'web'
  }

  const appLang = JSON.parse(localStorage.getItem('lang'))
  if (appLang.lang !== 'en') {
    config.url = `${appLang.lang}/${config.url}`
  }
  return config
}

export function onRequestError (error) {
  // Do something with request error
  return Promise.reject(error)
}

// Add a response interceptor
export function onResponse (response) {
  const { dispatch } = reduxStore
  const customResponse = response
  const { status } = response
  if (status === 500) {
    return Promise.reject(getUnknownError())
  } else if (status === 400) {
    const { errorMessage } =
      response.data.error[0] || (response.data ? response.data.message : '')
    return Promise.reject(getUnknownError(errorMessage))
  }
  customResponse.data = response.data
  let customMessage = {}
  customMessage = {
    message:
      response.data.message instanceof Array &&
      response.data.message[0]?.response_code,
    display:
      response.data.message instanceof Array &&
      response.data.message[0]?.display,
    type: 'SUCCESS'
  }
  customMessage.display && dispatch(showSnackbar(customMessage))
  return customResponse
}

export function onResponseError (error) {
  const { dispatch } = reduxStore
  let customError = { ...error }
  const status = Number(
    error.response ? error.response.headers.status || error.response.status : -1
  )
  if (status === 401 && window.location.pathname !== '/') {
    const lang = localStorage.getItem('lang')
    localStorage.clear()
    localStorage.setItem('lang', lang)
    window.location.reload('/')
  } else if (status === 500) {
    customError = getUnknownError()
  } else {
    customError = {
      response: {
        message:
          error.response.data.message instanceof Array
            ? error.response.data.message[0].response_code
            : error.response.data.message?.response_code,
        status: status,
        display:
          error.response.data.message instanceof Array
            ? error.response.data.message[0].display
            : error.response.data.message?.display,
        type: 'ERROR'
      }
    }
  }
  customError.response.display && dispatch(showSnackbar(customError.response))
  customError.response.message === 1038 && setTimeout(function () {
    const lang = localStorage.getItem('lang')
    localStorage.clear()
    localStorage.setItem('lang', lang)
    window.location.href = process.env.REACT_APP_BASE_PATH
  }, 3000)
  return Promise.reject(customError)
}

const getUnknownError = (message) => ({
  response: {
    message:
      message && typeof message === typeof ''
        ? message
        : 'Service unavailable. Please try after some time.',
    display: true,
    status: 500,
    type: 'ERROR'
  }
})

export const checkBackendUrl = () => {
  let baseURL = process.env.REACT_APP_ENVIRONMENT === 'prod' ? process.env.REACT_APP_BACKEND_URL : '/api'

  const url = new URL(window.location.href)
  if (process.env.REACT_APP_DEBUG && url.search.includes('backendUrl')) {
    const urlParams = url.searchParams
    baseURL = urlParams.get('backendUrl')
  }
  return baseURL
}
